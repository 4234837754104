const ACCESS = {
  // Dashboard
  DASHBOARD: 'public',

  // Solva AI
  SOLVA_AI: 'public',

  // Admin
  ADMIN: 'admin',
  ROLE: 'role',
  LOG: 'log',

  // Brand & Outlet
  BRAND: 'brand',
  OUTLET: 'outlet',
  HYBRID_OUTLET: 'hybrid-outlet',

  // Library
  MASTER_VOUCHER: 'master-voucher',
  PREFERENCE: 'preference',

  // Loyalty
  MEMBER: 'member',
  TIER: 'tier',
  BENEFIT: 'benefit',
  POINT_BRAND: 'point-brand',
  POINT_VALUE: 'point-value',
  POINT: 'earn-point',
  REWARD: 'reward',
  FEATURED_REWARD: 'featured-reward',
  EXTERNAL_REWARD: 'external-reward',
  REINSTATEMENT: 'reinstatement',
  BURN_VOUCHER: 'manual-burn-voucher',
  POINT_ADD: 'add-point',
  POINT_DEDUCT: 'deduct-point',

  // CRM
  GIFT_CODE: 'gift-code',
  CAMPAIGN: 'campaign-point-earning',
  WELCOME_MISSION: 'welcome-mission',
  TACTICAL_MISSION: 'tactical-mission',
  REFERRAL_PROGRAM: 'referral',

  // App Content
  BANNER: 'banner',
  NEWS_PROMO: 'news-or-promo',
  FAQ: 'faq',
  TERM_CONDITION: 'term-and-condition',
  PRIVACY_POLICY: 'privacy-and-policy',
  CONTACT_INFORMATION: 'contact-information',
  WEB_CONFIG: 'standardized-web',

  // Report
  REPORT_ENGAGEMENT: 'report-engagement',
  REPORT_FINANCIAL: 'report-financial',
  REPORT_TRX_RATING: 'transaction-rating',
  EXPORT_REPORT: 'export-report',

  // Outlet App
  USER_LIST: 'user-list'
}

const MODULES = [
  {
    text: 'Admin',
    childs: [
      {
        name: ACCESS.ADMIN,
        text: 'Admin List',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.ROLE,
        text: 'Admin Role',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Brand & Outlet',
    childs: [
      {
        name: ACCESS.BRAND,
        text: 'Brand',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.OUTLET,
        text: 'Outlet',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.HYBRID_OUTLET,
        text: 'Hybrid Outlet',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Library',
    childs: [
      {
        name: ACCESS.MASTER_VOUCHER,
        text: 'Master Voucher',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.PREFERENCE,
        text: 'Member Preferences',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Loyalty',
    childs: [
      {
        name: ACCESS.MEMBER,
        text: 'Member',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.TIER,
        text: 'Tier',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.BENEFIT,
        text: 'Benefit',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.POINT,
        text: 'Earn Points',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.POINT_BRAND,
        text: 'Points Branding',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.POINT_VALUE,
        text: 'Points Value',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.REWARD,
        text: 'Reward',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.FEATURED_REWARD,
        text: 'Featured Reward',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.EXTERNAL_REWARD,
        text: 'External Reward',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.REINSTATEMENT,
        text: 'Reinstatement',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.BURN_VOUCHER,
        text: 'Manual Burn Voucher',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.POINT_ADD,
        text: 'Add Point',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.POINT_DEDUCT,
        text: 'Deduct Point',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'CRM',
    childs: [
      {
        name: ACCESS.GIFT_CODE,
        text: 'Gift Code',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.CAMPAIGN,
        text: 'Multiplier Campaign',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.WELCOME_MISSION,
        text: 'Welcome Mission',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.TACTICAL_MISSION,
        text: 'Tactical Mission',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.REFERRAL_PROGRAM,
        text: 'Referral',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'App Content',
    childs: [
      {
        name: ACCESS.BANNER,
        text: 'Banner',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.NEWS_PROMO,
        text: 'News or Promo',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.FAQ,
        text: 'FAQ',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.TERM_CONDITION,
        text: 'Terms & Conditions',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.PRIVACY_POLICY,
        text: 'Privacy & Policy',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.CONTACT_INFORMATION,
        text: 'Contact Information',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.WEB_CONFIG,
        text: 'Web Config',
        actions: ['read', 'create', 'update']
      }
    ]
  },
  {
    text: 'Report',
    childs: [
      {
        name: ACCESS.REPORT_ENGAGEMENT,
        text: 'Engagement',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.REPORT_FINANCIAL,
        text: 'Report Financial',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.REPORT_TRX_RATING,
        text: 'Transaction Rating',
        actions: ['read', 'create', 'update']
      },
      {
        name: ACCESS.EXPORT_REPORT,
        text: 'Export Report',
        actions: ['read', 'create', 'update']
      }
    ]
  }
]

export default { ACCESS, MODULES }
